import type { ImageTitleProps } from 'src/components/articledisplays/ImageTitle';
import ImageTitle from 'src/components/articledisplays/ImageTitle';

//#region [Props]
type ImageTitleAspectPanoramaProps = Omit<ImageTitleProps, "forcedAspect">;
//#endregion

//#region [Component]
export default function ImageTitleAspectPanorama(props: ImageTitleAspectPanoramaProps) {
	return <ImageTitle {...props} forcedAspect={ASPECT_PANORAMA} />;
}
//#endregion

export const ASPECT_PANORAMA = 1440 / 580;
