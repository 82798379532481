import Ad from 'src/components/ads/Ad';
import ImageTitleAspect2_1 from 'src/components/articledisplays/ImageTitleAspect2_1';
import ImageTitleAspectSquare from 'src/components/articledisplays/ImageTitleAspectSquare';
import { isAdContent } from "src/components/forsiden/utilsv2";
import theme from 'src/components/themes/DefaultTheme';
import styled from 'src/lib/styles/css';
import { GroupedLayoutObject, IBlockWithAd, PublishableDocument } from "src/lib/types/IForsidenV2";
import IMovieList from "src/lib/types/MovieList";

//#region [Props]
type SquareWideAdBLockProps = {
	block: GroupedLayoutObject<IBlockWithAd>;
};
//#endregion

//#region [Component]
export default function SquareWideAdBLockV2({ block }: SquareWideAdBLockProps) {

	if ((block.articles?.length ?? 0) === 0) return null;
	const articles = block.articles! as Exclude<PublishableDocument, IMovieList>[];
	return <SSquareWideAdBLock>
		{articles.length > 0 && <SSquareArt
			document={articles[0]}
			sizes={SIZES}
			themeValue={block.themes0}
			isContentAd={isAdContent(articles[0])}
			useSectionTitle />}

		{articles.length > 1 && <SSmallArt
			document={articles[1]}
			sizes={SIZES}
			themeValue={block.themes1}
			isContentAd={isAdContent(articles[1])}
			useSectionTitle />}
		<SAd targetId='FW_fast_plass_forside' canHide={false} />
	</SSquareWideAdBLock>;
}
//#endregion

const SIZES = `(min-width: ${theme.maxContentWidth + 1}px) ${theme.maxContentWidth}px, ((max-width: ${theme.maxContentWidth}px) and ${theme.mq.desktop}) 50vw, 100vw`;

//#region [Styles]
const SSquareWideAdBLock = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media ${theme.mq.desktop} {
		display: grid;
		gap: 35px;

		@media (orientation: portrait) {
			gap: 15px;
		}

		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: auto;
		grid-auto-rows: auto;

		grid-template-areas:
			"squareArt smallArt"
			"squareArt ad";
	}
`;

const SSquareArt = styled(ImageTitleAspectSquare)`
	grid-area: squareArt;
`;

const SSmallArt = styled(ImageTitleAspect2_1)`
	grid-area: smallArt;
`;

const SAd = styled(Ad)`
	margin: 0 -10px;

	@media ${theme.mq.desktop} {
		grid-area: ad;
		margin: 0;
		#FW_fast_plass_forside {
			aspect-ratio: 802 / 380;
		}
	}
`;
//#endregion
