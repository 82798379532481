import OneThirdsImageOnLeft from "src/components/articledisplays/OneThirdsImageOnLeft";
import ButtonLink from "src/components/buttonlink/ButtonLink";
import FilmwebLink from "src/components/filmweblink/FilmwebLink";
import PopularMoviesList, { prefetch } from "src/components/popularmovieslist/PopularMoviesList";
import theme from "src/components/themes/DefaultTheme";
import styled from 'src/lib/styles/css';
import IFilmnytt from "src/lib/types/Filmnytt";
import { GroupedLayoutObject, IOneArticleAndPopMovies } from "src/lib/types/IForsidenV2";
import ISettings from "src/lib/types/Settings";

//#region [Props]
type OneArticleAndPopMoviesProps = {
    block: GroupedLayoutObject<IOneArticleAndPopMovies>;
    settings: ISettings;
};
//#endregion

const IMG_SIZES = `${theme.mq.desktop} 800px, 250px`;

export { prefetch };

//#region [Component]
export default function OneArticleAndPopMovies({ block, settings }: OneArticleAndPopMoviesProps) {
    return <SOneArticleAndPopMovies className={!settings.messageBannerActive ? "isAtTop" : undefined}>
        <SContentContainer>
            <SOneThirdsImageOnLeft document={block.articles![0] as IFilmnytt} sizes={IMG_SIZES} />
            <SPopularMoviesList hideHeader />
        </SContentContainer>
        {block.linkTitle && block.linkUrl && <SButtonLink href={block.linkUrl}>{block.linkTitle}</SButtonLink>}
    </SOneArticleAndPopMovies>;
}
//#endregion

//#region [Styles]
const SOneArticleAndPopMovies = styled.div`
    background: ${theme.palette.gradient2};
    padding: ${theme.maxContentPadding} ${theme.maxContentPadding} calc(${theme.maxContentPadding} * 3) ${theme.maxContentPadding};
    margin-bottom: calc( ${theme.maxContentPadding} * 2);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-left: -${theme.maxContentPadding};
    margin-right: -${theme.maxContentPadding};
    position: relative;

    &.isAtTop {
        margin-top: -${theme.maxContentPadding};
    }

    @media ${theme.mq.desktop} {
        padding: calc( ${theme.maxContentPadding} * 2) calc( ${theme.maxContentPadding} * 2) calc( ${theme.maxContentPadding} * 4) calc( ${theme.maxContentPadding} * 2);
        margin-bottom: calc( ${theme.maxContentPadding} * 2);
        &.isAtTop {
           margin-top: -35px;
        }
    }
`;

const SContentContainer = styled.div`
    display: flex;
    --flex-gap: 10px;
    gap: var(--flex-gap);
    flex-direction: column;

    @media ${theme.mq.desktop} {
        flex-direction: row;
    }
`;

const SOneThirdsImageOnLeft = styled(OneThirdsImageOnLeft)`
    @media ${theme.mq.desktop} {
        flex: 0 0 360px;
    }
`;

const SPopularMoviesList = styled(PopularMoviesList)`
    margin-right: -${theme.maxContentPadding};

    @media ${theme.mq.desktop} {
        margin-right: 0;
        flex: 1 1 auto;
    }
    --textcolor: ${theme.palette.lillaHovedfarge};
`;

const SButtonLink = styled(ButtonLink)`
    border: 2px solid ${theme.palette.lillaMørkHovedfarge};
    color: ${theme.palette.lillaMørkHovedfarge};
    background: ${theme.palette.buttonGradient4};

    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
    @media ${theme.mq.desktop} {
        font-size: ${theme.fonts.tittelXS.size};
    }
`;

//#endregion