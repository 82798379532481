import FilmwebLink from "src/components/filmweblink/FilmwebLink";
import theme from "src/components/themes/DefaultTheme";
import styled from "src/lib/styles/css";
import { IIconRow, IIconRowIcon } from "src/lib/types/IForsidenV2";

//#region [Props]
type IconRowProps = {
    block: IIconRow;
};
//#endregion

//#region [Component]
export default function IconRow({ block }: IconRowProps) {
    return <SIconRow>
        {block.icons.map(icon => <Icon key={icon._key} icon={icon} />)}
    </SIconRow>;
}
//#endregion

type IconProps = {
    icon: IIconRowIcon;
}

function Icon({ icon }: IconProps) {
    return <SIconLink to={icon.link}>
        <div dangerouslySetInnerHTML={{ __html: icon.svg }} />
        <SIconText>{icon.title}</SIconText>
        {!!icon.subtitle && <SIconText>{icon.subtitle}</SIconText>}
    </SIconLink>
}

//#region [Styles]
// NOTE: This uses scroll driven animations to detect if an item overflows
const SIconRow = styled.div`
    @keyframes detect-scroll {
        from,
        to {
            --can-scroll: 1;
        }
    }

    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    overflow-y: hidden;
    overflow-x: auto;

    animation: detect-scroll linear;
    animation-timeline: scroll(self inline);

    --bg-if-can-scroll: var(--can-scroll) flex-start;
    --bg-if-cant-scroll: center;
    justify-content: var(--bg-if-can-scroll, var(--bg-if-cant-scroll));

    scrollbar-width: thin;
    scrollbar-color: ${theme.palette.lilla1} var(--custom-gradient-stop0);
    scrollbar-gutter: stable;

    font-size: ${theme.fonts.bodytextS.size};

    @media ${theme.mq.desktop} {
        gap: 50px;
        font-size: ${theme.fonts.bodytextM.size};
    }
`;

const SIconLink = styled(FilmwebLink)`
    text-decoration: none;
    color: ${theme.palette.turkis2};
    &:hover {
        text-decoration: underline;
    }

    svg {
        width: 100%;
        display: block;
        height: auto;
        margin: 0 auto;
        max-width: 88px;
        margin-bottom: 0.5em;

        @media ${theme.mq.desktop} {
            max-width: 120px;
        }
    }

    flex: 0 0 88px;

    @media ${theme.mq.desktop} {
        flex: 0 0 120px;
    }
`;

const SIconText = styled.span`
    display: block;
    text-align: center;
    white-space: nowrap;
    @supports(background-clip: text) {
        color: transparent;
        background-clip: text;
        background-image: radial-gradient(circle at center, #b3adff 0, #aae6fb 20%, #ffc8be 50%, #f8aed6 75%, #b3adff 100%);
    }


`;
//#endregion