import ImageTitle from "src/components/articledisplays/ImageTitle";
import { ASPECT_2_1 } from "src/components/articledisplays/ImageTitleAspect2_1";
import FilmwebLink from "src/components/filmweblink/FilmwebLink";
import VerticalArticleList from "src/components/forsiden/VerticalArticleList";
import { isAdContent } from "src/components/forsiden/utilsv2";
import SomeFacebookSquareLogoSvg from "src/components/svg/SomeFacebookSquareLogoSvg";
import SomeInstagramSquareLogoSvg from "src/components/svg/SomeInstagramSquareLogoSvg";
import SomeTikTokLogoSvg from "src/components/svg/SomeTikTokLogoSvg";
import theme from "src/components/themes/DefaultTheme";
import styled from "src/lib/styles/css";
import IFilmnytt from "src/lib/types/Filmnytt";
import { IFilmomtale } from "src/lib/types/Filmomtale";
import { ThemeValues, type IArticlesAndList } from "src/lib/types/IForsidenV2";

//#region [Props]
type ArticlesAndListProps = {
    block: IArticlesAndList;
};
//#endregion

const SIZES = `(min-width: ${theme.maxContentWidth + 1}px) 518px, ((max-width: ${theme.maxContentWidth}px) and ${theme.mq.desktop}) 50%, 50vw`;

//#region [Component]
export default function ArticlesAndList({ block }: ArticlesAndListProps) {
    return <SArticleRowBlock>
        <div>
            <SArticleBlockHeader>
                {block.sectionTitle && <SArticleBlockHeaderTitle>{block.sectionTitle}</SArticleBlockHeaderTitle>}
                <SSocialMediaLabel>Følg oss:</SSocialMediaLabel>
                <SSocialMediaLink to="https://www.facebook.com/filmweb.no" rel="noopener noreferrer"><SomeFacebookSquareLogoSvg className="fbSocMedia" /></SSocialMediaLink>
                <SSocialMediaLink to="https://www.instagram.com/filmwebno/" rel="noopener noreferrer"><SomeInstagramSquareLogoSvg className="instaSocMedia" /></SSocialMediaLink>
                <SSocialMediaLink to="https://www.tiktok.com/@filmweb.no" rel="noopener noreferrer"><SomeTikTokLogoSvg className="tiktokSocMedia"/></SSocialMediaLink>
            </SArticleBlockHeader>
            <SArticlesContainer>
                {block.articles?.map((art, index) => <SImageTitle key={art._id}
                    document={art as (IFilmnytt | IFilmomtale)}
                    forcedAspect={ASPECT_2_1}
                    sizes={SIZES}
                    themeValue={block[`themes${index}`] as (ThemeValues | undefined)}
                    isContentAd={isAdContent(art)}
                    contentAdLabelPlacementMobile="bottom"
                />)}
            </SArticlesContainer>
        </div>
        <SVerticalArticleList block={block.layouts?.[0]!} />
    </SArticleRowBlock>;
}
//#endregion

//#region [Styles]
const SArticleRowBlock = styled.div`
    --gap: 10px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: var(--gap);

    @media ${theme.mq.desktop} {
        --gap: 35px;

        display: grid;
        grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);

    }
`;

const SArticleBlockHeader = styled.div`
    display: flex;

    color: var(--textcolor);
    align-items: center;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    gap: 15px;

    @media ${theme.mq.desktop} {
        margin-bottom: 1em;
        margin-top: 0;
        height: 55px;
        gap: 40px;
    }
`;

const SArticleBlockHeaderTitle = styled.h2`
    flex: 1 0 auto;

    @media ${theme.mq.desktop} {
        font-size: ${theme.fonts.tittelL.size};
    }
`;

const SSocialMediaLabel = styled.div`
    flex: 0 0 auto;
    vertical-align: middle;
    color: ${theme.palette.lyslilla};
    font-size: ${theme.fonts.tittelS.size};
`;

const SSocialMediaLink = styled(FilmwebLink)`
    display: block;

    svg {
		max-height: 46px;
        display: block;
	}

    flex: 0 0 25px;
    @media ${theme.mq.desktop} {
        flex: 0 0 46px;
    }

    .fbSocMedia, .instaSocMedia, .tiktokSocMedia {
        path {
            fill: url(#fbsoc-media-gradient) #fff;
        }
    }

    #fbsoc-media-gradient, #toktoksoc-media-gradient, #instasoc-media-gradient {
        --gradient-start: #CFBFFF;
        --gradient-end: #8CB7FC;
    }
`;

const SVerticalArticleList = styled(VerticalArticleList)`
    header {

        display: flex;
        align-items: center;
        @media ${theme.mq.desktop} {
            height: 55px;
            margin-top: 0;
        }
    }
`;

const SArticlesContainer = styled.div`
    display: grid;
    gap: var(--gap);
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
`;

const SImageTitle = styled(ImageTitle)`
    h1 {

        @media ${theme.mq.mobile} {
            font-size: ${theme.fonts.tittelXXS.size};
        }
    }

`;
//#endregion
