import ImageTitle from 'src/components/articledisplays/ImageTitle';
import { ASPECT_2_1 } from 'src/components/articledisplays/ImageTitleAspect2_1';
import { isAdContent } from "src/components/forsiden/utilsv2";
import theme from 'src/components/themes/DefaultTheme';
import styled, { css } from 'src/lib/styles/css';
import { GroupedLayoutObject, IArticleRowBlock24, PublishableDocument, ThemeValues } from "src/lib/types/IForsidenV2";
import IMovieList from "src/lib/types/MovieList";


//#region [Props]
type ArticleRowBlock24Props = {
    block: GroupedLayoutObject<IArticleRowBlock24>;
};
//#endregion

const SIZES_FOUR = `(min-width: ${theme.maxContentWidth + 1}px) ${theme.maxContentWidth / 4}px, ((max-width: ${theme.maxContentWidth}px) and ${theme.mq.desktop}) 25vw, 50vw`;
const SIZES_TWO = `(min-width: ${theme.maxContentWidth + 1}px) ${theme.maxContentWidth / 2}px, ((max-width: ${theme.maxContentWidth}px) and ${theme.mq.desktop}) 50vw, 50vw`;

const SIZES = [SIZES_TWO, SIZES_FOUR];

//#region [Component]
export default function ArticleRowBlock24({ block }: ArticleRowBlock24Props) {
    if ((block?.articles?.length ?? 0) < 2) return null;

    const articles = block.articles!.slice(0, 4) as Exclude<PublishableDocument, IMovieList>[];

    return <SArticleRowBlock>
        {articles!.map((a, index) => {

            if (!a) {
                return null;
            }
            return <SImageTitle
                key={`${a._id}_${index}`}
                sizes={articles.length === 2 ? SIZES[0] : SIZES[1]}
                forcedAspect={ASPECT_2_1}
                document={a}
                themeValue={block[`themes${index}`] as (ThemeValues | undefined)}
                isContentAd={isAdContent(a)}
                $numArticles={block!.articles!.length}
                useSectionTitle />
        })
        }

    </SArticleRowBlock>;
}
//#endregion

//#region [Styles]
const SArticleRowBlock = styled.div`
	display: flex;
	flex-direction: row;
    flex-wrap: wrap;
	--flex-gap: 10px;
    gap: var(--flex-gap);
	@media ${theme.mq.desktop} {
		--flex-gap: 35px;
	}
`;

type SImageTitleProps = {
    $numArticles: number;
};

const SImageTitle = styled(ImageTitle) <SImageTitleProps>`
	flex: 0 0 calc(50% - var(--flex-gap) / 2);

    h1 {
        font-size: ${theme.fonts.tittelXXS.size};
        @media ${theme.mq.desktop} {
            font-size: ${theme.fonts.tittelXS.size};
        }
    }



	@media ${theme.mq.desktop} {
		flex: 0 0 ${props => {
        switch (props.$numArticles) {
            case 2:
                return css`calc(50% - var(--flex-gap) / 2)`;
            default:
                return css`calc(25% - var(--flex-gap) * 3 / 4 )`;
        }
    }}
	}
`;
//#endregion
