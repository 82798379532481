import classNames from "classnames";
import SquareImageLeftTitle from "src/components/articledisplays/SquareImageLeftTitle";
import ButtonLink from "src/components/buttonlink/ButtonLink";
import FilmwebLink from "src/components/filmweblink/FilmwebLink";
import RoundNewsPageWithBling from "src/components/svg/RoundNewsPageWithBling";
import theme from "src/components/themes/DefaultTheme";
import styled from "src/lib/styles/css";
import { GroupedLayoutObject, ISlimArticleBlock } from "src/lib/types/IForsidenV2";

//#region [Props]
type SlimArticleBlockProps = {
    block: GroupedLayoutObject<ISlimArticleBlock>;
};
//#endregion

//#region [Component]
export default function SlimArticleBlock({ block }: SlimArticleBlockProps) {
    const hasLink = !!(block.linkUrl && block.linkTitle);

    return <section>
        {block.label && <SListHeader><h2>{block.label}</h2></SListHeader>}
        <SList className={classNames(block.themes ?? "dark", block.showSeparators ? "withSeparators" : undefined, hasLink ? "withLink" : undefined)}>
            <SItemContainer className={classNames(block.showSeparators ? "withSeparators" : undefined, hasLink ? "withLink" : undefined)}>
                {block.articles.slice(0, 3).map(doc => <SSquareImageLeftTitle key={doc._id}
                    document={doc}
                    themeValue={block.themes}
                    showSeparator={block.showSeparators}
                    imageWidth={64}
                    />)}
            </SItemContainer>
            {hasLink && <SListLink href={block.linkUrl}>
                <SRoundNewsPageWithBling>
                    <SIconSvg />
                </SRoundNewsPageWithBling>
                {block.linkTitle}
            </SListLink>}
        </SList>


    </section>;
}
//#endregion


//#region [Styles]
const SListHeader = styled.header`
    color: var(--textcolor);

    margin-bottom: 0.5em;

    h2 {
        font-size: ${theme.fonts.tittelS.size};
        @media ${theme.mq.desktop} {
            font-size: ${theme.fonts.tittelXS.size};
        }
    }

`;

const SList = styled.div`
    border-radius: 8px;
    padding: 12px;
    padding-bottom: 50px;

    background: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));

    &.light {
        background: var(--TEXTCOLOR_OVERRIDE, var(--textcolor));
    }

    &.lightGradient {
        background: ${theme.palette.gradient1};
    }

    &.gold {
        background: ${theme.palette.gullHovedfarge};
    }

    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;

    &.withLink {
        margin-bottom: 20px;
    }

    @media ${theme.mq.desktop} {
        display: block;
        padding: 0;

        background: none !important;

        &.withLink {
            display: flex;
            gap: 20px;
            flex-direction: row;
            margin-bottom: 0;

        }
    }

`;

const SItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media ${theme.mq.mobile} {
        &.withSeparators {
            gap: 30px;
        }
    }

    @media ${theme.mq.desktop} {
        gap: 20px;
        flex-direction: row;

        &.withLink {
            flex: 0 0 calc(75% - 10px);
        }
    }


`;

const SSquareImageLeftTitle = styled(SquareImageLeftTitle)`

    background: none;

    @media ${theme.mq.desktop} {
        flex: 1 0 0;
        padding: 10px;
        border-radius: ${theme.stdBorderRadius};

        &.withSeparator:after {
            content: none !important;
        }

        background: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));

        &.light {
            background: var(--TEXTCOLOR_OVERRIDE, var(--textcolor));
        }

        &.lightGradient {
            background: ${theme.palette.gradient1};
        }

        &.gold {
            background: ${theme.palette.gullHovedfarge};
        }

        h1 {
            font-size: ${theme.fonts.tittelXXS.size};
        }
    }
`;

const SListLink = styled(ButtonLink)`
    background: ${theme.palette.gradient1};
    color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));
    text-decoration: none;
    font-weight: bold;
    gap: 20px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);

    &:hover {
        text-decoration: underline;
    }
    border: 2px solid var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));

    @media ${theme.mq.desktop} {
        transform: none;
        position: static;
        flex: 0 0 calc(25% - 10px);
        justify-content: flex-start;
        border: none;
        border-radius: ${theme.stdBorderRadius};
        padding: 10px;

        display: flex;
        align-items: center;
        font-size: ${theme.fonts.tittelXS.size};
    }
`;

const SRoundNewsPageWithBling = styled.div`
    display: none;
    @media ${theme.mq.desktop} {
        border-radius: 50%;
        background-color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));
        padding: 6px;
        display: block;
        box-sizing: border-box;
        aspect-ratio: 1 / 1;
        flex: 0 0 64px
    }
`;

const SIconSvg = styled(RoundNewsPageWithBling)`
    display: block;
    aspect-ratio: 1 / 1;

    max-height: none !important;
    height: auto !important;
    margin: 0 !important;

`;
//#endregion