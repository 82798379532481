import type { ImageTitleProps } from 'src/components/articledisplays/ImageTitle';
import ImageTitle from 'src/components/articledisplays/ImageTitle';

//#region [Props]
type ImageTitleAspectSquareProps = Omit<ImageTitleProps, "forcedAspect">;
//#endregion

//#region [Component]
export default function ImageTitleAspectSquare(props: ImageTitleAspectSquareProps) {
	return <ImageTitle {...props} forcedAspect={ASPECT_SQUARE} />;
}
//#endregion

export const ASPECT_SQUARE = 1;