import VerticalArticleList from "src/components/forsiden/VerticalArticleList";
import theme from 'src/components/themes/DefaultTheme';
import styled from 'src/lib/styles/css';
import { GroupedLayoutObjectWithSubgroups, IVerticalArticleList, IVerticalArticleListGroup } from "src/lib/types/IForsidenV2";


//#region [Props]
type VerticalArticleListBlockProps = {
    block: GroupedLayoutObjectWithSubgroups<IVerticalArticleListGroup, IVerticalArticleList>;
};
//#endregion

const SIZES_FOUR = `(min-width: ${theme.maxContentWidth + 1}px) ${theme.maxContentWidth / 4}px, ((max-width: ${theme.maxContentWidth}px) and ${theme.mq.desktop}) 25vw, 50vw`;
const SIZES_TWO = `(min-width: ${theme.maxContentWidth + 1}px) ${theme.maxContentWidth / 2}px, ((max-width: ${theme.maxContentWidth}px) and ${theme.mq.desktop}) 50vw, 50vw`;

const SIZES = [SIZES_TWO, SIZES_FOUR];

//#region [Component]
export default function VerticalArticleListBlock({ block }: VerticalArticleListBlockProps) {
    if ((block?.layouts?.length ?? 0) !== 3) return null;

    return <SArticleRowBlock>
        {block.layouts!.map((a, index) => {
            if (!a) {
                return null;
            }

            return <VerticalArticleList key={a._key} block={a} />

        })}

    </SArticleRowBlock>;
}
//#endregion

//#region [Styles]
const SArticleRowBlock = styled.div`
	--gap: 10px;

    display: flex;
	flex-direction: column;
    flex-wrap: wrap;
    gap: var(--gap);

    @media ${theme.mq.desktop} {
		--gap: 35px;

        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

	}
`;


//#endregion
