import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';

//#region [Props]
type RoundNewsPageWithBlingSvgProps = {
} & StandardSvgProps;
//#endregion

//#region [Component]
export default function RoundNewsPageWithBling({ className }: RoundNewsPageWithBlingSvgProps) {
    return (<svg className={className} viewBox="0 0 120.708 120.708">
        <defs>
            <linearGradient id="linear-roundNewsWithBling" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="var(--custom-gradient-stop0, #b3adff)" />
                <stop offset="0.241" stop-color="var(--custom-gradient-stop1,#aae6fb)" />
                <stop offset="0.488" stop-color="var(--custom-gradient-stop2,#ffc8be)" />
                <stop offset="0.764" stop-color="var(--custom-gradient-stop3,#f8aed6)" />
                <stop offset="1" stop-color="var(--custom-gradient-stop4,#b3adff)" />
            </linearGradient>
        </defs>
        <g>
            <path
                id="NewsMark2.svg"
                d="m 42.622,39.827 h 32.7 a 2.843,2.843 0 0 1 2.843,2.843 v 37.676 a 2.843,2.843 0 0 1 -2.843,2.843 h -32.7 A 2.843,2.843 0 0 1 39.779,80.346 V 42.671 a 2.843,2.843 0 0 1 2.843,-2.844 z m 2.843,3.554 h 27.014 a 2.844,2.844 0 0 1 2.843,2.843 v 8.531 a 2.844,2.844 0 0 1 -2.843,2.844 H 45.465 a 2.844,2.844 0 0 1 -2.843,-2.844 v -8.534 a 2.844,2.844 0 0 1 2.844,-2.84 z m 0,17.772 h 9.242 a 2.843,2.843 0 0 1 2.843,2.843 v 4.266 a 2.843,2.843 0 0 1 -2.843,2.843 h -9.242 a 2.844,2.844 0 0 1 -2.843,-2.843 v -4.266 a 2.843,2.843 0 0 1 2.844,-2.843 z m 17.061,0 H 73.19 a 2.1325,2.1325 0 0 1 0,4.265 H 62.526 a 2.1325,2.1325 0 0 1 0,-4.265 z m 0,5.687 H 73.19 a 2.1325,2.1325 0 0 1 0,4.265 H 62.526 a 2.1325,2.1325 0 0 1 0,-4.265 z M 89.952,35.129 q -7.444,0.3 -7.755,7.719 a 0.313,0.313 0 0 1 -0.626,0 q -0.308,-7.411 -7.749,-7.727 a 0.3095,0.3095 0 0 1 0,-0.619 q 7.443,-0.309 7.755,-7.727 a 0.31,0.31 0 0 1 0.619,0 q 0.308,7.421 7.755,7.733 a 0.3095,0.3095 0 0 1 0,0.619 z M 79.239,26.42 q -4.98,0.2 -5.19,5.161 a 0.206,0.206 0 1 1 -0.412,0 q -0.2,-4.958 -5.182,-5.168 a 0.2065,0.2065 0 1 1 0,-0.413 q 4.98,-0.2 5.182,-5.161 a 0.213,0.213 0 0 1 0.419,0 q 0.2,4.958 5.183,5.168 a 0.2065,0.2065 0 0 1 0,0.413 z"
                fill-rule="evenodd"
                fill="url(#linear-roundNewsWithBling)"
                />
            <path
                id="Ellipse_83"
                data-name="Ellipse 83"
                d="M 60.354,4.115 A 56.255,56.255 0 0 0 38.465,112.175 56.255,56.255 0 0 0 82.243,8.533 55.883,55.883 0 0 0 60.354,4.115 M 60.354,0 A 60.354,60.354 0 1 1 0,60.354 60.354,60.354 0 0 1 60.354,0 Z"
                fill="url(#linear-roundNewsWithBling)"
                />
        </g>
    </svg>)
}
//#endregion