import Ad from 'src/components/ads/Ad';
import ImageTitle from 'src/components/articledisplays/ImageTitle';
import { ASPECT_2_1 } from 'src/components/articledisplays/ImageTitleAspect2_1';
import { ASPECT_PANORAMA } from 'src/components/articledisplays/ImageTitleAspectPanorama';
import ButtonLink from "src/components/buttonlink/ButtonLink";
import PopularMoviesRow from 'src/components/forsiden/PopularMoviesRow';
import { isAdContent } from "src/components/forsiden/utilsv2";
import theme from 'src/components/themes/DefaultTheme';
import styled, { css } from 'src/lib/styles/css';
import { GroupedLayoutObject, ITopBlock, PublishableDocument } from "src/lib/types/IForsidenV2";
import IMovieList from "src/lib/types/MovieList";

//#region [Other]
const TOP_SIZES_MULTIPLE = `(min-width: ${theme.maxContentWidth + 1}px) ${theme.maxContentWidth / 2}px, ((max-width: ${theme.maxContentWidth}px) and ${theme.mq.desktop}) 50vw, 100vw`;
const TOP_SIZES_SINGLE = `(min-width: ${theme.maxContentWidth + 1}px) ${theme.maxContentWidth}px, 100vw`;
//#endregion

//#region [Props]
type TopBlockProps = {
    block: GroupedLayoutObject<ITopBlock>;

};
//#endregion

//#region [Component]
export default function TopBlockV2({ block }: TopBlockProps) {
    if ((block.articles?.length ?? 0) === 0) return null;
    const topArticles = (block.articles?.slice(0, 2) ?? []) as Exclude<PublishableDocument, IMovieList>[];
    if (topArticles.length === 0) {
        return null;
    }
    return <STopBlock>

        <SArticleImageTitle $isFullWidth={topArticles.length === 1}
            sizes={topArticles.length === 1 ? TOP_SIZES_SINGLE : TOP_SIZES_MULTIPLE}
            document={topArticles[0]}
            forcedAspect={topArticles.length > 1 ? ASPECT_2_1 : ASPECT_PANORAMA}
            themeValue={block.themes0}
            isContentAd={isAdContent(topArticles[0])}
            useSectionTitle
        />

        <SNewsLinkContainer>
            <SToNewsSectionButtonLink href='/filmnytt'>Les flere filmnyheter</SToNewsSectionButtonLink>
        </SNewsLinkContainer>

        <PopularMoviesRow />

        <STopAd targetId='FW_topp_forside' canHide={false} height="300" mobileScreenWidth />

        {topArticles.length > 1 && <SArticleImageTitle
            sizes={TOP_SIZES_MULTIPLE}
            document={topArticles[1]}
            forcedAspect={ASPECT_2_1}
            themeValue={block.themes1}
            isContentAd={isAdContent(topArticles[1])}
            useSectionTitle />}


    </STopBlock>;
}
//#endregion

//#region [Styles]
const STopBlock = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media ${theme.mq.desktop} {
		display: grid;
		gap: 35px;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: auto;
		grid-template-areas:
			"top       top"
            "newslink  newslink"
			"posterRow posterRow"
			"topAd     topAd";
	}
`;



const CSSFullWidth = css`
	grid-area: top;

	h1 {
		font-size: 22px;
		font-size: min(max(22px, 2vw), 30px);// fluid scale, min 24 px, max 54px.
		font-size: clamp(22px, 2vw, 30px); // same version as above, but easier to read
	}
`;

const CSSSplitWidth = css`
	grid-row: 1 / span 1;
	grid-column-end: span 1;
`;

type SArticleImageTitleProps = {
    $isFullWidth?: boolean;
};

const SArticleImageTitle = styled(ImageTitle) <SArticleImageTitleProps>`
	@media ${theme.mq.desktop} {
		${props => props.$isFullWidth === true ? CSSFullWidth : CSSSplitWidth}
	}
`;

const STopAd = styled(Ad)`

	@media ${theme.mq.desktop} {
		width: 100%;
		grid-area: topAd;
		margin: 0;
	}

	@media (max-width: ${theme.maxContentWidth}px) {
		padding: 0;
	}
`;

const SNewsLinkContainer = styled.div`
    grid-area: newslink;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SToNewsSectionButtonLink = styled(ButtonLink)`
	background: ${theme.palette.buttonGradient2};
	color: ${theme.textColorDark};
	font-size: ${theme.fonts.buttontextL.size};
	font-weight: bold;

`;
//#endregion