import ImageTitle from 'src/components/articledisplays/ImageTitle';
import { ASPECT_2_1 } from 'src/components/articledisplays/ImageTitleAspect2_1';
import { ASPECT_PANORAMA } from 'src/components/articledisplays/ImageTitleAspectPanorama';
import { ASPECT_SQUARE } from 'src/components/articledisplays/ImageTitleAspectSquare';
import { isAdContent } from "src/components/forsiden/utilsv2";
import theme from 'src/components/themes/DefaultTheme';
import styled, { css } from 'src/lib/styles/css';
import { GroupedLayoutObject, IArticleRowBlock, ISquareArticleRowBlock, PublishableDocument, ThemeValues } from "src/lib/types/IForsidenV2";
import IMovieList from "src/lib/types/MovieList";


//#region [Props]
type ArticleRowBlockProps = {
    block: GroupedLayoutObject<IArticleRowBlock | ISquareArticleRowBlock>;
    isSquare?: boolean;
};
//#endregion

const SIZES_THREE = `(min-width: ${theme.maxContentWidth + 1}px) ${theme.maxContentWidth / 3}px, ((max-width: ${theme.maxContentWidth}px) and ${theme.mq.desktop}) 33vw, 100vw`;
const SIZES_TWO = `(min-width: ${theme.maxContentWidth + 1}px) ${theme.maxContentWidth / 2}px, ((max-width: ${theme.maxContentWidth}px) and ${theme.mq.desktop}) 50vw, 100vw`;
const SIZES_SINGLE = `(min-width: ${theme.maxContentWidth + 1}px) ${theme.maxContentWidth}px, 100vw`;

const SIZES = [SIZES_SINGLE, SIZES_TWO, SIZES_THREE];

//#region [Component]
export default function ArticleRowBlockV2({ block, isSquare = false }: ArticleRowBlockProps) {
    if ((block?.articles?.length ?? 0) === 0) return null;
    let aspect = block!.articles!.length === 1 ? ASPECT_PANORAMA : ASPECT_2_1;
    let maxArticles = 3;
    if (isSquare) {
        aspect = ASPECT_SQUARE;
        maxArticles = 2;
    }
    const articles = block.articles!.slice(0, maxArticles) as Exclude<PublishableDocument, IMovieList>[];

    return <SArticleRowBlock>
        {articles!.map((a, index) => {

            if (!a) {
                return null;
            }
            return <SImageTitle
                key={`${a._id}_${index}`}
                sizes={isSquare ? SIZES[1] : SIZES[block!.articles!.length - 1]}
                forcedAspect={aspect}
                document={a}
                themeValue={block[`themes${index}`] as (ThemeValues | undefined)}
                isContentAd={isAdContent(a)}
                $numArticles={block!.articles!.length}
                useSectionTitle />
        })
        }

    </SArticleRowBlock>;
}
//#endregion

//#region [Styles]
const SArticleRowBlock = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	gap: 10px;

	@media ${theme.mq.desktop} {
		flex-direction: row;
		--flex-gap: 35px;
		gap: var(--flex-gap);


	}
`;

type SImageTitleProps = {
    $numArticles: number;
};

const SImageTitle = styled(ImageTitle) <SImageTitleProps>`
	flex: 0 0 100%;

	@media ${theme.mq.desktop} {
		flex: 0 0 ${props => {
        switch (props.$numArticles) {
            case 1:
                return "100%";
            case 2:
                return css`calc(50% - var(--flex-gap) / 2)`;
            default:
                return css`calc(33% - (var(--flex-gap) * 2) / 4 )`;
        }
    }};
	}
`;
//#endregion
