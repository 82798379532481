import IFilmnytt from "src/lib/types/Filmnytt";
import { getImage, getUrlForDoctype } from "src/lib/types/sanity";
import styled, { css } from 'src/lib/styles/css';
import FilmwebLink from "src/components/filmweblink/FilmwebLink";
import theme from "src/components/themes/DefaultTheme";
import CmsImage from "src/components/images/CmsImage";
import DocumentIcon from "src/components/documenticon/DocumentIcon";

//#region [Props]
type OneThirdsImageOnLeftProps = {
    document: IFilmnytt;
    className?: string;
    isStreamingContext?: boolean;
    useSectionTitle?: boolean;
    //isContentAd?: boolean;
    headerLevel?: 1 | 2 | 3 | 4 | 5 | 6;
    //contentAdLabelPlacementMobile?: "overlay" | "bottom"
    sizes: string;
};
//#endregion

//#region [Component]
export default function OneThirdsImageOnLeft({ document, className, isStreamingContext = false, useSectionTitle = false, headerLevel = 1, sizes }: OneThirdsImageOnLeftProps) {
    const slug = document.slug.current;
    const url = getUrlForDoctype(document._type, slug, document.isStreaming as boolean | undefined || isStreamingContext, document);
    const title = useSectionTitle ? (document.sectionPageTitle as string ?? document.title) : document.title;
    const image = getImage(document);
    return <SImageTitle className={className}>
        <SFilmwebLink to={url!}>
            {image && <SCmsImage image={image} alt={image.asset?.altText ?? ""} crop sizes={sizes}/>}
            <SHeader >
                <SH as={headerLevel !== 1 ? `h${headerLevel}` : undefined}>{title}</SH>
                <DocumentIcon document={document} />
            </SHeader>
        </SFilmwebLink>
    </SImageTitle>;
}
//#endregion

//#region [Styles]
const SImageTitle = styled.article`
	overflow: hidden;
	border-radius: ${theme.stdBorderRadius};
	position: relative;
    background: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));
    color: var(--TEXTCOLOR_OVERRIDE, var(--textcolor));
`;

const SFilmwebLink = styled(FilmwebLink)`
	text-decoration: none;
	height: 100%;
	z-index: 0;
	display: flex;
    color: currentcolor;
    max-width: 100%;

    &:hover {
		text-decoration: underline;
	}

    @media ${theme.mq.desktop} {
        flex-direction: column;
    }
`;

type SCmsImageProps = {
    $aspect?: number;
}

const SCmsImage = styled(CmsImage) <SCmsImageProps>`
	object-fit: contain;
    flex: 0 0 33%;
    width: 33%;

    @media ${theme.mq.desktop} {
        flex: 0 0 auto;
        width: 100%;
    }
`;

const SH = styled.h1`
	order: 2;
	font-weight: bold;
	font-size: 16px;
	overflow: hidden;
    white-space: normal;
    @media ${theme.mq.desktop} {
        font-size: 22px;
    }
`;

const SHeader = styled.header`
	position: relative;
	padding: 0 min(20px, 3.75%);
	flex: 1 0 auto;
    max-width: 66%;
	display: flex;
	flex-direction: column;
    align-self: center;

    @media ${theme.mq.desktop} {
        max-width: 100%;
        padding: min(20px, 3.75%);
    }
`;
//#endregion